<template>
    <div>
        <h1>ExportSingleOutput</h1>
        <ExportForecastDataToWordSingle 
            :output="outputSingle" 
            :topicName="topic.name"
            :valueDisplayOption="valueDisplayOption" 
            :decimalsOption="decimalsOption" 
        />
    </div>

    <div>
        <h1>ExportOutput</h1>
        <ExportForecastDataToWordVersus 
            :output="outputVersus" 
            :topicName="topic.name"
            :valueDisplayOption="valueDisplayOption" 
            :decimalsOption="decimalsOption"
            @endDataFinish="endDataFinish()" 
        />
    </div>


    <!-- <div v-if="exportOutputTables">
        {{ exportOutputTables }}
    </div> -->

    <!-- <div v-if="exportOutputCharts">
        {{ exportOutputCharts  }}
    </div> -->
    
    <!-- <div v-if="exportOutputVersusTables">
        {{ exportOutputVersusTables }}
    </div> -->

    <!-- <div v-if="exportOutputVersusCharts">
        {{ exportOutputVersusCharts }}
    </div> -->
</template>

<script>
import ExportForecastDataToWordSingle from '../components/ExportForecastDataToWord/ExportForecastDataToWordSingle.vue';
import ExportForecastDataToWordVersus from '../components/ExportForecastDataToWord/ExportForecastDataToWordVersus.vue';
import { exportToWord } from '../components/ExportForecastDataToWord/exportToWord.service';
import { useToast } from 'vue-toastification';


const toast = useToast();

export default {
    name: 'ProgramOutputExportPreview',
    components: {
        ExportForecastDataToWordSingle,
        ExportForecastDataToWordVersus,
    },
    emits: ['setFalseLoadingExportToDocx'],
    props: ['outputVersus', 'outputSingle', 'topic', 'valueDisplayOption', 'decimalsOption', 'exportLastYear', 'exportFirstYear', 'segmentationSelected'] ,
    data() {
        return {
            dataFinish: false, 

        }
    },
    mounted() {
        // console.log(this.outputVersus);
        // console.log(this.outputSingle);
        // console.log(this.topic);
        // console.log(this.valueDisplayOption);
        // console.log(this.decimalsOption);
        // console.log(this.exportFirstYear);
        // console.log(this.exportLastYear);
        // console.log(this.segmentationSelected);
        // mainTitle

    },
    computed: {
        exportOutputTables(){
            return this.$store.getters.obtainExportOutputTables;
        },
        exportOutputCharts(){
            return this.$store.getters.obtainExportOutputCharts;
        },
        exportOutputVersusTables(){
            return this.$store.getters.obtainExportOutputVersusTables;
        },
        exportOutputVersusCharts(){
            return this.$store.getters.obtainExportOutputVersusCharts;
        },

    },

    methods: {
        endDataFinish(){
            this.dataFinish = true;
        },
        // potential Solution 
        svgToPng(svg, callback) {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const img = new Image();
            const svgBlob = new Blob([svg], { type: 'image/svg+xml;charset=utf-8' });
            const url = URL.createObjectURL(svgBlob);

            img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0);
                URL.revokeObjectURL(url);
                callback(canvas.toDataURL('image/png'));
            };

            img.src = url;
        },


        setFalseLoadingExportToDocx(){
            this.$emit("setFalseLoadingExportToDocx");
        },

        // async exportToDoc() {
        //     // console.log(this.exportOutputCharts);
        //     // console.log(this.exportOutputTables);

        //     // console.log('exportOutputVersusCharts', this.exportOutputVersusCharts);
        //     // console.log('exportOutputVersusTables', this.exportOutputVersusTables);

        //     // SIMPLESEGMENTATION 
        //     let tables = [];
        //     this.exportOutputTables.map( (exportOutputTable) => tables.push(exportOutputTable.content) )

        //     let charts = [];
        //     this.exportOutputCharts.map( (exportOutputChart) => charts.push(exportOutputChart.content) )

        //     const pngDataUrls = [];

        //     const convertChartToPng = (chart) => {
        //         return new Promise((resolve) => {
        //             // this.svgToPng(chart.toSvg(), (pngDataUrl) => {
        //             this.svgToPng(chart, (pngDataUrl) => {
        //                 resolve(pngDataUrl);
        //             });
        //         });
        //     };

        //     for (let chart of charts) {
        //         const pngDataUrl = await convertChartToPng(chart);
        //         pngDataUrls.push(pngDataUrl);
        //     }

        //     const preHtml = `
        //     <html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>
        //     <head><meta charset='utf-8'><title>Export HTML to Word Document</title></head><body>`;

        //     const postHtml = "</body></html>";

        //     let bodyContent = '';

        //     // Main TITLE
        //     bodyContent += `<h1 style="font-size:20px;">A.${this.outputSingle[0].segmentations[0].order + 1} Market Forecast to ${this.exportLastYear} by ${this.outputSingle[0].segmentations[0].name}</h1><br />`;
        //     // SubTitle1
        //     bodyContent += `<h2 style="font-size:18px;">A.${this.outputSingle[0].segmentations[0].order + 1}.1 market by ${this.outputSingle[0].segmentations[0].name} overview</h2> <br />`;
        //     // Table1 
        //     bodyContent += `<h3 style="font-size:18px;">${this.topic.name} Market Forecast to ${this.exportLastYear} by ${this.outputSingle[0].segmentations[0].name} ${this.valueDisplayOption}</h3> <br />`
        //     bodyContent += `${tables[0]} <br />`

        //     // Chart1
        //     bodyContent += `<h3 style="font-size:18px;">${this.topic.name} Market Forecast to ${this.exportLastYear} by ${this.outputSingle[0].segmentations[0].name} ${this.valueDisplayOption}</h3> <br />`
        //     bodyContent += `<img src="${pngDataUrls[0]}" style="max-width:100%; height:auto;" /><br>`;
        //     // Chart2
        //     bodyContent += `<h3 style="font-size:18px;">${this.topic.name} Market Forecast to ${this.exportLastYear} by ${this.outputSingle[0].segmentations[0].name} %</h3> <br />`
        //     bodyContent += `<img src="${pngDataUrls[1]}" style="max-width:100%; height:auto;" /><br>`;

        //     // // VERSUS SEGMENTATION

        //     // // Sort versus charts and tables
        //     // // Tables 
        //     // let sortedBySegmentVersusTables = []; 
        //     // JSON.parse(JSON.stringify(this.exportOutputVersusTables)).forEach(item => {
        //     //     if(!sortedBySegmentVersusTables[item.order]){
        //     //         sortedBySegmentVersusTables[item.order] = [];
        //     //     }
        //     //     sortedBySegmentVersusTables[item.order].push(item);
        //     // });
            
        //     // // // charts
        //     // let sortedBySegmentVersusCharts = []; 
        //     // JSON.parse(JSON.stringify(this.exportOutputVersusCharts)).forEach(item => {
        //     //     if(!sortedBySegmentVersusCharts[item.order]){
        //     //         sortedBySegmentVersusCharts[item.order] = [];
        //     //     }
        //     //     sortedBySegmentVersusCharts[item.order].push(item);
        //     // })

        //     // // console.log('sortedBySegmentVersusTables',sortedBySegmentVersusTables);
        //     // // console.log('sortedBySegmentVersusCharts',sortedBySegmentVersusCharts);



        //     // // sortedBySegmentVersusTables.forEach( async (segm, segmIndex)=> {

        //     // //     let tablesVersus = [];
        //     // //     segm.map( (exportOutputTable) => tablesVersus.push(exportOutputTable.content) );

        //     // //     let chartsVersus = [];
        //     // //     sortedBySegmentVersusCharts[segmIndex].map( (exportOutputChart) => chartsVersus.push(exportOutputChart.content) );

        //     // //     const pngDataUrlsVersus = [];

        //     // //     for (let chartVersus of chartsVersus) {
        //     // //         const pngDataUrl = await convertChartToPng(chartVersus);
        //     // //         pngDataUrlsVersus.push(pngDataUrl);
        //     // //     }

        //     // //     console.log('pngDataUrlsVersus',pngDataUrlsVersus);

        //     // //     // SubTitle
        //     // //     bodyContent += `<h2 style="font-size:18px;">A.${this.outputSingle[0].segmentations[0].order + 1 + segmIndex + 1} ${this.topic.name} market ${segm[0].segmentation1} by ${segm[0].segmentation2} overview </h2> <br />`;
                
        //     // //     segm.forEach( (segmRow,segmRowIndex  ) => {
        //     // //         // Segm title
        //     // //         bodyContent += `<h3 style="font-size:18px;">A.${this.outputSingle[0].segmentations[0].order + 1 + segmIndex + 1}.${segmIndex + 1} ${segmRow.segmentationItem} market by ${segm[0].segmentation2} overview</h3> <br />`;

        //     // //         // h4 
        //     // //         bodyContent += `<h4 style="font-size:18px;">${segmRow.segmentationItem}  ${this.topic.name} Market Forecast to ${this.exportLastYear} by ${segm[0].segmentation2} ${this.valueDisplayOption}</h4> <br />`;
        //     // //         // table
        //     // //         bodyContent += `${segmRow.content}`;

        //     // //         // h4 
        //     // //         bodyContent += `<h4 style="font-size:18px;">${segmRow.segmentationItem}  ${this.topic.name} Market Forecast to ${this.exportLastYear} by ${segm[0].segmentation2}</h4> <br />`;
        //     // //         // chart
        //     // //         bodyContent += `<img src="${pngDataUrlsVersus[segmRowIndex]}" style="max-width:100%; height:auto;" /><br>`;

        //     // //         // h4 
        //     // //         // bodyContent += `<h4 style="font-size:18px;">${segmRow.segmentationItem}  ${this.topic.name} Market Forecast to ${this.exportLastYear} by ${segm[0].segmentation2} % </h4> <br />`;
        //     // //         // chart TODO ADD NEW CHART ON MARKET FORECAST, THEN CREATE NEW VUEX TO ADD THE NEW CHARTS
        //     // //         // bodyContent += `<img src="${pngDataUrlsVersus[segmRowIndex]}" style="max-width:100%; height:auto;" /><br>`;
        //     // //     })
        //     // //     // Tables
        //     // //     // // Tabletitles
        //     // // })


        //     // for (let [segmIndex, segm] of sortedBySegmentVersusTables.entries()) {
        //     //     let tablesVersus = [];
        //     //     segm.map( (exportOutputTable) => tablesVersus.push(exportOutputTable.content) );

        //     //     let chartsVersus = [];
        //     //     sortedBySegmentVersusCharts[segmIndex].map( (exportOutputChart) => chartsVersus.push(exportOutputChart.content) );

        //     //     const pngDataUrlsVersus = [];

        //     //     for (let chartVersus of chartsVersus) {
        //     //         const pngDataUrl = await convertChartToPng(chartVersus);
        //     //         pngDataUrlsVersus.push(pngDataUrl);
        //     //     }

        //     //     // console.log('pngDataUrlsVersus',pngDataUrlsVersus);

        //     //     // SubTitle
        //     //     bodyContent += `<h2 style="font-size:18px;">A.${this.outputSingle[0].segmentations[0].order + 1 + segmIndex + 1} ${this.topic.name} market ${segm[0].segmentation1} by ${segm[0].segmentation2} overview </h2> <br />`;

        //     //     segm.forEach( (segmRow,segmRowIndex ) => {
        //     //         // Segm title
        //     //         bodyContent += `<h3 style="font-size:18px;">A.${this.outputSingle[0].segmentations[0].order + 1 + segmIndex + 1}.${segmIndex + 1} ${segmRow.segmentationItem} market by ${segm[0].segmentation2} overview</h3> <br />`;

        //     //         // h4 
        //     //         bodyContent += `<h4 style="font-size:18px;">${segmRow.segmentationItem}  ${this.topic.name} Market Forecast to ${this.exportLastYear} by ${segm[0].segmentation2} ${this.valueDisplayOption}</h4> <br />`;
        //     //         // table
        //     //         bodyContent += `${segmRow.content}`;

        //     //         // h4 
        //     //         bodyContent += `<h4 style="font-size:18px;">${segmRow.segmentationItem}  ${this.topic.name} Market Forecast to ${this.exportLastYear} by ${segm[0].segmentation2}</h4> <br />`;
        //     //         // chart
        //     //         bodyContent += `<img src="${pngDataUrlsVersus[segmRowIndex]}" style="max-width:100%; height:auto;" /><br>`;

        //     //         // h4 
        //     //         // bodyContent += `<h4 style="font-size:18px;">${segmRow.segmentationItem}  ${this.topic.name} Market Forecast to ${this.exportLastYear} by ${segm[0].segmentation2} % </h4> <br />`;
        //     //         // chart TODO ADD NEW CHART ON MARKET FORECAST, THEN CREATE NEW VUEX TO ADD THE NEW CHARTS
        //     //         // bodyContent += `<img src="${pngDataUrlsVersus[segmRowIndex]}" style="max-width:100%; height:auto;" /><br>`;
        //     //     });
        //     // }
        //     // const html = `${preHtml}${bodyContent}${postHtml}`;

        //     // const blob = new Blob(['\ufeff', html], {
        //     //     type: 'application/msword'
        //     // });

        //     // // Especifica el nombre del archivo
        //     // const url = URL.createObjectURL(blob);
        //     // const a = document.createElement('a');
        //     // a.href = url;
        //     // a.download = 'exported-document.doc';
        //     // document.body.appendChild(a);
        //     // a.click();
        //     // URL.revokeObjectURL(url);
        // },


        async exportToDoc() {
            // SIMPLESEGMENTATION 
            let tables = [];
            this.exportOutputTables.map( (exportOutputTable) => tables.push(exportOutputTable.content) )

            let charts = [];
            this.exportOutputCharts.map( (exportOutputChart) => charts.push(exportOutputChart.content) )

            const preHtml = `<div>`;

            const postHtml = "</div>";

            let bodyContent = '';

            // Main TITLE
            // bodyContent += `<h1 style="font-size: 20px; color: red;">A.${this.outputSingle[0].segmentations[0].order + 1} Market Forecast to ${this.exportLastYear} by ${this.outputSingle[0].segmentations[0].name}</h1><br />`;
                //REMOVE A.INDEX 
            bodyContent += `<h1 style="font-size: 20px; color: red;">Market Forecast to ${this.exportLastYear} by ${this.outputSingle[0].segmentations[0].name}</h1><br />`;
            // SubTitle1
            // bodyContent += `<h2 style="font-size:18px;">A.${this.outputSingle[0].segmentations[0].order + 1}.1 market by ${this.outputSingle[0].segmentations[0].name} overview</h2> <br />`;
                //REMOVE A.INDEX 
            bodyContent += `<h2 style="font-size:18px;">${this.topic.name} Market by ${this.outputSingle[0].segmentations[0].name} overview</h2> <br />`;
            // Chart1
            bodyContent += `<h3 style="font-size:18px;">${this.topic.name} Market Forecast to ${this.exportLastYear} by ${this.outputSingle[0].segmentations[0].name} [US$ ${this.valueDisplayOption}]</h3> <br />`
            bodyContent += `${charts[0]} <br />`
            
            // Table1 
            bodyContent += `<h3 style="font-size:18px;">${this.topic.name} Market Forecast to ${this.exportLastYear} by ${this.outputSingle[0].segmentations[0].name} [US$ ${this.valueDisplayOption}]</h3> <br />`
            bodyContent += `${tables[0]} <br />`
            
            // Chart2
            bodyContent += `<h3 style="font-size:18px;">${this.topic.name} Market Forecast to ${this.exportLastYear} by ${this.outputSingle[0].segmentations[0].name} %</h3> <br />`
            bodyContent += `${charts[1]} <br />`

            // VERSUS SEGMENTATION
            // Sort versus charts and tables
            // Tables 
            let sortedBySegmentVersusTables = []; 
            JSON.parse(JSON.stringify(this.exportOutputVersusTables)).forEach(item => {
                if(!sortedBySegmentVersusTables[item.order]){
                    sortedBySegmentVersusTables[item.order] = [];
                }
                sortedBySegmentVersusTables[item.order].push(item);
            });
            
            // // charts
            let sortedBySegmentVersusCharts = []; 
            JSON.parse(JSON.stringify(this.exportOutputVersusCharts)).forEach(item => {
                if(!sortedBySegmentVersusCharts[item.order]){
                    sortedBySegmentVersusCharts[item.order] = [];
                }
                sortedBySegmentVersusCharts[item.order].push(item);
            })

            for (let [segmIndex, segm] of sortedBySegmentVersusTables.entries()) {
                let tablesVersus = [];
                segm.map( (exportOutputTable) => tablesVersus.push(exportOutputTable.content) );

                let chartsVersus = [];
                sortedBySegmentVersusCharts[segmIndex].map( (exportOutputChart) => chartsVersus.push(exportOutputChart.content) );

                const dataUrlsVersus = [];

                for (let chartVersus of chartsVersus) {
                    dataUrlsVersus.push(chartVersus);
                }
                // SubTitle
                // bodyContent += `<h2 style="font-size:18px;">A.${this.outputSingle[0].segmentations[0].order + 1 + segmIndex + 1} ${this.topic.name} market ${segm[0].segmentation1} by ${segm[0].segmentation2} overview </h2> <br />`;
                    // REMOVE A.index 
                bodyContent += `<h2 style="font-size:18px;">${this.topic.name} market ${segm[0].segmentation1} by ${segm[0].segmentation2} overview </h2> <br />`;

                segm.forEach( (segmRow,segmRowIndex ) => {
                    // Segm title
                    // bodyContent += `<h3 style="font-size:18px;">A.${this.outputSingle[0].segmentations[0].order + 1 + segmIndex + 1}.${segmIndex + 1} ${segmRow.segmentationItem} market by ${segm[0].segmentation2} overview</h3> <br />`;
                        // REMOVE A.index 
                    bodyContent += `<h3 style="font-size:18px;">${segmRow.segmentationItem} market by ${segm[0].segmentation2} overview</h3> <br />`;

                    // h3 
                    bodyContent += `<h4 style="font-size:18px;">${segmRow.segmentationItem}  ${this.topic.name} Market Forecast to ${this.exportLastYear} by ${segm[0].segmentation2} [US$ ${this.valueDisplayOption}]</h4> <br />`;
                    // table
                    bodyContent += `${segmRow.content}`;

                    // h4 
                    bodyContent += `<h4 style="font-size:18px;">${segmRow.segmentationItem}  ${this.topic.name} Market Forecast to ${this.exportLastYear} by ${segm[0].segmentation2}</h4> <br />`;
                    // chart
                    // bodyContent += `<img src="${dataUrlsVersus[segmRowIndex]}" style="max-width:100%; height:auto;" /><br>`;
                    bodyContent += `${dataUrlsVersus[segmRowIndex]}<br>`;

                    // h4 
                    // bodyContent += `<h4 style="font-size:18px;">${segmRow.segmentationItem}  ${this.topic.name} Market Forecast to ${this.exportLastYear} by ${segm[0].segmentation2} % </h4> <br />`;
                    // chart TODO ADD NEW CHART ON MARKET FORECAST, THEN CREATE NEW VUEX TO ADD THE NEW CHARTS
                    // bodyContent += `<img src="${pngDataUrlsVersus[segmRowIndex]}" style="max-width:100%; height:auto;" /><br>`;
                });
            }
            const fullHtml = preHtml + bodyContent + postHtml

            try {
                // create an object FormData to send backend 
                const formData = new FormData();
                formData.append('htmlContent', fullHtml);

                // Send content to  backend to convetion
                // const response = await axios.post('http://localhost:5011/api/conversion/convert', formData, {
                //     responseType: 'blob'  // Esperar un archivo binario de respuesta
                // });

                // const response = await exportToWord(formData);
                // topicName, segmentation, years
                await exportToWord(formData, this.topic.name, this.segmentationSelected );
                // console.log('successResponse',response);
                toast.success('Successfully download');
                // Crear un enlace para descargar el archivo DOCX
                // const url = window.URL.createObjectURL(new Blob([response.data]));
                // const link = document.createElement('a');
                // link.href = url;
                // link.setAttribute('download', 'documento.docx'); // Nombre del archivo
                // document.body.appendChild(link);
                // link.click();
            } catch (error) {
                console.error('Error Convertion:', error);
                toast.error('Error DOCX convertion');
            }
            finally{
                this.setFalseLoadingExportToDocx();
            }
        },

    },
    watch: {
        // 'exportOutputTables': {
        //     async handler(newVal) {
        //         if (newVal) {
        //             if(this.exportOutputCharts.length === 2 && this.exportOutputTables.length === 1){
        //                 // await this.exportToDoc();
        //             }
        //         }
        //     },
        //     deep: true,
        // },

        dataFinish:{
            async handler(newVal) {
                if (newVal) {
                    await this.exportToDoc();
                }
            },
        } 
    },
    beforeUnmount() {
        console.log('dismounted');
    },
}

</script>

<style></style>